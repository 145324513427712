import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DMSService } from "@app/dms.service";

declare var $: any;


@Component({
    selector: 'dms-admin-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.css']
})
export class DMSAdminClientsComponent {
    clientsList: any = [];
    currentUser;
    form: FormGroup;
    file: any;


    constructor(private dmsService: DMSService, private fb: FormBuilder) {
        this.form = this.fb.group({
            Hostname: [''],
            IPAddress: [''],
            Location: ['']
        }
        );
        this.currentUser = JSON.parse(localStorage.getItem('DMS_CURRENT_USER_DATA'));

    }
    ngOnInit(): void {
        this.dmsService.listOfClients.subscribe((clients) => {
            console.log("client chaged");
            this.clientsList = clients;
        });
    }
    addClient() {
        let formVlues = this.form.value;
        formVlues['createdBy'] = this.currentUser['UserName'];
        this.dmsService.addClientsByAdmin(formVlues).subscribe(res => {
            this.dmsService.getClientsListForAdmin().subscribe(res => {
                this.clientsList = res;
            })
            $('#addclientCancelBtn').click();
            alert("Client is registered successfully.");
        });
    }



}