import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DMSService } from "@app/dms.service";

declare var $: any;


@Component({
    selector: 'dms-admin-shares',
    templateUrl: './shares.component.html',
    styleUrls: ['./shares.component.css']
})
export class DMSAdminSharesComponent {
    sharesList: any = [];
    serversList: any = [];
    form: FormGroup;


    constructor(private dmsService: DMSService, private fb: FormBuilder) {
        this.form = this.fb.group({
            Name: [''],
            Directory: [''],
            Password: [''],
            ServerID: ['']
        }
        );
        this.sharesList = JSON.parse(localStorage.getItem('DMS_SHARES_LIST'));
        this.serversList = JSON.parse(localStorage.getItem('DMS_SERVER_LIST'));
    }
    ngOnInit() {
        this.dmsService.listOfServers.subscribe((servers) => {
            this.serversList = servers;
        });
    }
    addShare() {
        let formVlues = this.form.value;
        this.dmsService.addShareByAdmin(formVlues).subscribe(res => {
            this.dmsService.getSharesListForAdmin().subscribe();
            $('#addshareCancelBtn').click();
            alert("Share is registered successfully.");
        });
    }


}