import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/helpers';
import { DMSAdminComponent } from './dms-admin/dms-admin.component';
import { DMSComponent } from './dms/dms.component';
import { LoginComponent } from './login/login.component';
import { AdminDataResolve } from './resolver/admin.resolve';
import { OrganizationDataResolve } from './resolver/organization.resolve';
import { SalesforceExtensionComponent } from './salesforce-extension/salesforce-extension.component';
import { SalesforceComponent } from './salesforce/salesforce.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      organizationData: OrganizationDataResolve
    }
  },
  {
    path: 'dms',
    component: DMSComponent
  },
  {
    path: 'salesforce',
    component: SalesforceComponent
  },
  {
    path: 'salesforce-ext',
    component: SalesforceExtensionComponent
  },
  {
    path: 'admin',
    component: DMSAdminComponent,
    resolve: {
      adminData: AdminDataResolve
    }
  },
  {
    path: '',
    component: LoginComponent,
    resolve: {
      organizationData: OrganizationDataResolve
    }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
