import { Organization } from './../organization/organization.model';
import { Action } from '@ngrx/store';

export const ORGANIZATION_DETAILS = 'ORGANIZATION_DETAILS';

export function getOrganizationReducer(state: Organization[] = [], action) {
    switch (action.type) {
        case ORGANIZATION_DETAILS:
            return [...state, action.payload];
        default:
            return state;
    }
}