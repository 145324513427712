import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DMSService } from "@app/dms.service";

declare var $: any;


@Component({
    selector: 'dms-admin-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class DMSAdminUsersComponent {
    usersList: any = [];
    clientList: any = [];
    projectsList: any = [];
    currentUserData;
    form: FormGroup;
    showForm: boolean = false;
    roleOptions: any = [];
    totalNoOfMounts: number = 0;

    options = ["FileTransfer", "LiveSync"];
    checkboxesAll: any;

    constructor(private dmsService: DMSService, private fb: FormBuilder, private ref: ChangeDetectorRef) {
        this.form = this.fb.group({
            FirstName: [''],
            LastName: [''],
            UserName: [''],
            Password: [''],
            ClientID: [''],
            EmployeeID: [''],
            Role: [''],
            AccessControls: this.fb.array([this.mountpoints])
        }
        );

        this.usersList = JSON.parse(localStorage.getItem('DMS_USER_LIST'));
        this.clientList = JSON.parse(localStorage.getItem('DMS_CLIENTS_LIST'));
        this.projectsList = JSON.parse(localStorage.getItem('DMS_PROJECTS_LIST'));
        console.log("this.projectsList---before===>", this.projectsList);
        this.currentUserData = JSON.parse(localStorage.getItem('DMS_CURRENT_USER_DATA'));
        if (this.currentUserData.Role == 'SuperAdmin') {
            this.roleOptions = ["User", "Admin"];
        } else {
            this.roleOptions = ["User"];
        }
        this.showForm = true;
        // const AccessControls = <FormGroup>this.form.get('AccessControls');
        // this.options.forEach((option: any) => {
        //     AccessControls.addControl(option, new FormControl(true));
        // });
    }
    ngOnInit() {
        this.dmsService.listOfClients.subscribe((clients) => {
            this.clientList = clients;
        });
        console.log("this.projectsList---after===>", this.projectsList);
        this.dmsService.listOfProjects.subscribe((projects) => {
            this.projectsList = projects;
            console.log("project updated");
            this.mountsUpdate(this.projectsList.length, this.projectsList);
        });
    }

    addUser() {
        let formVlues = this.form.value;

        formVlues['createdBy'] = this.currentUserData['UserName'];
        this.dmsService.addUserByAdmin(formVlues).subscribe(res => {
            if (res['response'] == 'failure') {
                alert(res['message']);
            } else {
                this.dmsService.getUsersListForAdmin().subscribe(res => {
                    this.usersList = res;
                });

                $('#adduserCancelBtn').click();
                alert("User is registered successfully.");
            }

        });
    }
    get mountpoints(): FormGroup {
        return this.fb.group({
            ShareID: [''],
            FileTransfer: [false],
            LiveSync: [false]
        });
    }
    mountsUpdate(value, data?) {
        const control = <FormArray>this.form.get('AccessControls');
        while (control.length !== 0) {
            control.removeAt(0);
        }
        for (let i = 0; i < value; i++) {
            if (data && data.length == value) {
                control.push(this.fb.group({
                    ShareID: [data[i].ProjectID],
                    FileTransfer: [false],
                    LiveSync: [false]
                }));
            } else {
                control.push(this.mountpoints)
            }
        }

    }
    addMount(value) {
        const control = <FormArray>this.form.get('AccessControls');
        for (let i = 1; i <= value; i++) {
            control.push(this.mountpoints)
        }
        this.totalNoOfMounts = this.totalNoOfMounts + 1;

        this.ref.detectChanges();
    }
    removeMount() {
        const control = <FormArray>this.form.get('AccessControls');
        if (control.length != 0) {
            control.removeAt(0);
            this.totalNoOfMounts = this.totalNoOfMounts - 1;
        }
    }


}