import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '@core/services';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    refreshingInProgress: boolean;
    constructor(private authenticationService: AuthenticationService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log("url==>", request.url);
        // console.log("urlparam==>", request.params);
        if ((request.url == "http://localhost:8080/login/v1") || (request.url == "http://localhost:8080/client/get/caresoft")) {
            //console.log("going direct==>", request);
            return next.handle(request);
        }

        // console.log("coming down");
        // add authorization header with jwt token if available
        // console.log("yoo=>", localStorage.getItem("accessToken"));
        let accessToken = localStorage.getItem("accessToken");
        //console.log("accessToken==>", accessToken)
        // console.log("indepartr==>", token);
        if (accessToken) {


            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });


        } else {
            request = request.clone({
                setHeaders: {
                    authorization: ``
                }
            });
        }
        return next.handle(request);
        // return next.handle(request).pipe(catchError(error => {
        //     if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
        //         // get refresh tokens
        //         //console.log("yoo 401 error");
        //         return this.refreshToken(request, next);
        //     }
        //     // console.log('Caught in CatchError. Throwing error')
        //     throw new Error(error)
        // }))
    }
    // private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     if (!this.refreshingInProgress) {
    //         this.refreshingInProgress = true;
    //         this.authenticationService.accessTokenSubject.next(null);
    //         //localStorage.setItem('accessToken', null);
    //         return this.authenticationService.refreshToken().pipe(
    //             switchMap((res) => {
    //                 this.refreshingInProgress = false;
    //                 this.authenticationService.accessTokenSubject.next(res['jwt']);
    //                 localStorage.setItem('accessToken', res['jwt']);
    //                 // repeat failed request with new token
    //                 return next.handle(this.addAuthorizationHeader(request, res['jwt']));
    //             })
    //         );
    //     } else {
    //         // wait while getting new token
    //         // return this.authenticationService.accessTokenSubject.pipe(
    //         //     filter(token => token !== null),
    //         //     take(1),
    //         //     switchMap(token => {
    //         //         // repeat failed request with new token
    //         //         return next.handle(this.addAuthorizationHeader(request, token));
    //         //     }));
    //         // wait while getting new token

    //         // repeat failed request with new token
    //         let accessToken = localStorage.getItem("accessToken");
    //         return next.handle(this.addAuthorizationHeader(request, accessToken));


    //     }
    // }
    private addAuthorizationHeader(request, token) {
        return request.clone({
            setHeaders: {
                authorization: `Bearer ${token}`
            }
        });
    }
}