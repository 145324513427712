import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DMSService } from "@app/dms.service";

declare var $: any;

@Component({
  selector: "app-salesforce-extension",
  templateUrl: "./salesforce-extension.component.html",
  styleUrls: ["./salesforce-extension.component.css"],
})
export class SalesforceExtensionComponent {
  searchForm: FormGroup;
  searchResult: any = [];

  isShowArchival: boolean;
  isShowReterieval: boolean;
  isShowAdvancedSearch: boolean;

  archivalResponse: any = [];
  isArchivedDone: boolean;

  isShowFieldsContainer: boolean;
  isShowFilterBox: boolean;

  listOfAllObjects: any = [];

  objectSelected = "0";
  allFieldsForSelectedObject: any = [];

  fieldSelected = "0";
  recordsToBeArchival: any = [];

  showArchivalRecordsToBeDone: boolean;

  recordsToBeArchivalColumn: any = [];
  advancedSearchColumn: any = [];
  isShowADSFilterBox: boolean = false;
  isALlChecked: boolean = false;

  isShowAddFilterBox: boolean;
  addFilterField = "";
  addFilterOperator = "";
  addFilterValue = "";
  addFilterObject = "0";
  searchValue = "";
  allFieldsForFilterObject: any = [];
  isShowFilterFieldsBox: boolean;
  showFilterSearchBoxandicons: boolean;

  savedFilterItems: any = [];

  constructor(private fb: FormBuilder, private dmsService: DMSService) {
    this.searchForm = this.fb.group({
      searchtext: [""],
    });
    this.getAllObjects();
    //this.showArchival();
  }
  ngOnInit() {}

  searchSubmit() {
    let body;
    this.searchResult = [];
    this.advancedSearchColumn = [];
    if (this.savedFilterItems.length > 0) {
      body = {
        object: this.addFilterObject,
        conditions: this.savedFilterItems,
        searchValue: this.searchValue,
      };
    } else {
      body = {
        object: this.addFilterObject,
        conditions: null,
        searchValue: this.searchValue,
      };
    }

    this.dmsService.advancedSearch(body).subscribe(async (res) => {
      //   this.searchResult.push(res.data[0]);
      //   this.searchResult.push(res.data[1]);
      //console.log(res);
      if (res.data[0].data.length > 0) {
        //console.log("cominginsf==>", res.data[0].data.length);
        for (let i = 0; i < res.data[0].data.length; i++) {
          let typ = "salesforce";
          res.data[0].data[i]["source"] = typ;
          let r = res.data[0].data[i];
          //console.log("rrr=>", r);
          delete r.attributes;
          this.searchResult.push(r);
          //console.log("hereonly==>", this.searchResult);
        }
      }
      if (res.data[1].data.length > 0) {
        // console.log("comingindyna");
        for (let i = 0; i < res.data[1].data.length; i++) {
          let typ = "dynavisor";
          res.data[1].data[i]["source"] = typ;
          let r = res.data[1].data[i];
          delete r.attributes;
          this.searchResult.push(r);
        }
      }

      //console.log("object==>", this.savedFilterItems[0].object);
      if (
        this.addFilterObject == "Customer__c" &&
        (res.data[0].data.length > 1 || res.data[1].data.length > 1)
      ) {
        //console.log("fdfdgere");
        this.searchResult = this.searchResult.filter(
          (s, index, self) =>
            index === self.findIndex((t) => t.CustomerId__c === s.CustomerId__c)
        );
      }

      //get columns
      if (this.searchResult.length > 0) {
        this.advancedSearchColumn = Object.keys(this.searchResult[0]);
      }
      // console.log("dsd=>", this.searchResult);
      this.isShowADSFilterBox = false;
      // if (this.searchResult.length > 0) {
      //   console.log("deletinggg");
      //   this.advancedSearchColumn.shift();
      //   this.searchResult.shift();
      //   this.searchResult.splice(1, 0, this.searchResult.pop());
      // }

      //console.log(this.searchResult);
    });
  }
  archivNow() {
    this.isArchivedDone = false;
    const selectFieldsID = this.fieldSelected;
    const selectedFieldValue = $("#selectedFieldValue").val();

    let ids = this.recordsToBeArchival
      .filter((x) => x.checked == true)
      .map((x) => {
        return "'" + x.Id + "'";
      });
    console.log(ids);
    let body = {
      selectFieldsID: selectFieldsID,
      selectedFieldValue: selectedFieldValue,
      selectedObject: this.objectSelected,
      selectedIDS: ids,
    };
    this.dmsService.archiveNowLatest(body).subscribe((res) => {
      this.isArchivedDone = true;
      this.archivalResponse = res.data.records;
    });
  }
  archivNowFetch() {
    this.isArchivedDone = false;
    const selectFieldsID = this.fieldSelected;
    const selectedFieldValue = $("#selectedFieldValue").val();

    let body = {
      selectFieldsID: selectFieldsID,
      selectedFieldValue: selectedFieldValue,
      selectedObject: this.objectSelected,
    };
    this.dmsService.archivenowFetch(body).subscribe(async (res) => {
      this.recordsToBeArchivalColumn = [];
      this.showArchivalRecordsToBeDone = true;
      this.recordsToBeArchival = res.data;
      if (this.recordsToBeArchival.length > 0) {
        this.recordsToBeArchivalColumn = Object.keys(
          this.recordsToBeArchival[0]
        );
      }

      this.recordsToBeArchivalColumn.shift();
      this.recordsToBeArchival.shift();
      await this.recordsToBeArchival.map((obj) => ({ ...obj, checked: false }));

      console.log(this.recordsToBeArchival);
      console.log(this.recordsToBeArchivalColumn);
    });
  }
  showArchival() {
    this.clearAll();
    this.isShowArchival = true;
    this.isShowReterieval = false;
    this.isShowAdvancedSearch = false;
  }
  showRetrieval() {
    this.clearAll();
    this.isShowArchival = false;
    this.isShowReterieval = true;
    this.isShowAdvancedSearch = false;
  }
  showAdvancedSearch() {
    this.clearAll();
    this.isShowArchival = false;
    this.isShowReterieval = false;
    this.isShowAdvancedSearch = true;
  }
  checkValue(event: any) {
    console.log(event);
  }
  showFieldsContainer() {
    console.log(this.objectSelected);
    let body = {
      objectName: this.objectSelected,
    };
    this.dmsService.getAllFields(body).subscribe((res) => {
      if (res.data.length > 0) {
        this.allFieldsForSelectedObject = Object.keys(res.data[0]);
        this.isShowFieldsContainer = true;
      } else {
        this.isShowFieldsContainer = false;
        alert("No fields found");
      }
    });
  }
  showFilterBox() {
    this.isShowFilterBox = true;
  }
  getAllObjects() {
    this.dmsService.getAllObjects().subscribe((res) => {
      this.listOfAllObjects = res.data;
    });
  }
  clearAll() {
    this.isShowArchival = false;
    this.isShowReterieval = false;
    this.isShowAdvancedSearch = false;
    this.isArchivedDone = false;
    this.isShowFieldsContainer = false;
    this.isShowFilterBox = false;
    this.showArchivalRecordsToBeDone = false;
    this.searchResult = [];
    this.isShowADSFilterBox = false;
    this.isShowAddFilterBox = false;
    this.objectSelected = "0";
  }
  selectall(source) {
    let checkboxes = $(".chkboxes");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = source.checked;
    }
  }

  showADSFilterBox() {
    this.isShowADSFilterBox = true;
  }
  hideADSFilterBox() {
    this.isShowADSFilterBox = false;
    this.isShowAddFilterBox = false;
  }
  CheckAllOptions() {
    if (this.recordsToBeArchival.every((val) => val.checked == true)) {
      this.recordsToBeArchival.forEach((val) => {
        val.checked = false;
      });
      this.isALlChecked = false;
    } else {
      this.recordsToBeArchival.forEach((val) => {
        val.checked = true;
      });
      this.isALlChecked = true;
    }
  }
  updateMainCheckbox() {
    if (this.recordsToBeArchival.every((val) => val.checked == true)) {
      this.isALlChecked = true;
    } else {
      this.isALlChecked = false;
    }
  }
  showAddFilterBox() {
    if (this.savedFilterItems.length > 0) {
      this.addFilterObject = this.savedFilterItems[0].object;
    }
    this.isShowAddFilterBox = true;
  }
  addFilter() {
    this.savedFilterItems.push({
      object: this.addFilterObject,
      field: this.addFilterField,
      operator: this.addFilterOperator,
      value: this.addFilterValue,
    });
    this.isShowAddFilterBox = false;
  }
  removeFilter(id?: any) {
    console.log(id);
    if (!isNaN(id)) {
      this.savedFilterItems = [
        ...this.savedFilterItems.slice(0, id),
        ...this.savedFilterItems.slice(id + 1),
      ];
    } else {
      this.savedFilterItems = [];
    }
  }
  showFieldsForFilterObject() {
    let body = {
      objectName: this.addFilterObject,
    };
    this.dmsService.getAllFields(body).subscribe((res) => {
      if (res.data.length > 0) {
        this.allFieldsForFilterObject = Object.keys(res.data[0]);
        this.isShowFilterFieldsBox = true;
      } else {
        this.isShowFilterFieldsBox = false;
        alert("No fields found");
      }
    });
  }
}
