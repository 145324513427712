import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/services';
import { SocketioService } from '../socketio.service';
import 'regenerator-runtime/runtime';
import { DMSService } from '@app/dms.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Organization } from '@app/organization/organization.model';
import { AppState } from '@app/app.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  organization: Observable<Organization[]>;
  imagePath: any;
  constructor(
    private fb: FormBuilder,
    private socketService: SocketioService,
    private dMSService: DMSService,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private route: ActivatedRoute
  ) {
    // this.organization = this.store.select(state => state.organization);
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.organization = this.route.snapshot.data.organizationData.data[0];

    console.log("org data in login page==>", this.organization['OrganizationID']);
    //this.getClientLogo('caresoft');

  }

  ngOnInit() {
    this.store.dispatch({
      type: 'ORGANIZATION_DETAILS',
      payload: this.organization
    });
    console.log('stored in store');
  }
  handleErrors(err) {
    console.log("error came");
  }

  login() {
    let username = this.loginForm.controls['username'].value;
    let password = this.loginForm.controls['password'].value;

    this.router.navigate(['/salesforce']);
    return false;

    if (username != '' && password != '') {
      let data = {
        "UserName": username,
        "Password": password,
        "OrganizationID": this.organization['OrganizationID']
      }
      this.authService.login(data).subscribe(res => {

        if (res && res['response'] == "success") {
          localStorage.setItem('DMS_CURRENT_USER_DATA', JSON.stringify(res['data']));
          if (res['data'].Role == 'User') {
            this.router.navigate(['/dms']);
          } else {
            this.router.navigate(['/admin']);
          }


        }
      })
    } else {
      alert('Please enter details.');
    }


    // this.router.navigate(['/dms']);
  }
  // testing() {
  //   this.authService.getClusterListDummy().subscribe(res => {
  //     //console.log("allclusters==>", res);
  //   });
  // }

}
