import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DMSService } from "@app/dms.service";

declare var $: any;


@Component({
    selector: 'dms-admin-servers',
    templateUrl: './servers.component.html',
    styleUrls: ['./servers.component.css']
})
export class DMSAdminServersComponent {
    serversList: any = [];
    form: FormGroup;
    file: any;
    currentUserData;


    constructor(private dmsService: DMSService, private fb: FormBuilder) {
        this.form = this.fb.group({
            Name: [''],
            Hostname: [''],
            IPAddress: [''],
            Port: ['6403'],
            Location: ['']
        }
        );
        this.serversList = JSON.parse(localStorage.getItem('DMS_SERVER_LIST'));
        this.currentUserData = JSON.parse(localStorage.getItem('DMS_CURRENT_USER_DATA'));
    }
    ngOnInit(): void {
        this.dmsService.listOfServers.subscribe((clients) => {
            console.log("server chaged");
            this.serversList = clients;
        });
    }
    addServer() {
        let formVlues = this.form.value;
        formVlues['SSHCertificate'] = this.file;
        formVlues['createdBy'] = this.currentUserData['UserName'];
        this.dmsService.addServerByAdmin(this.form.value).subscribe(res => {
            this.dmsService.getServersListForAdmin().subscribe();
            $('#addserverCancelBtn').click();
            alert("Server is registered successfully.");
        });
    }
    onChange(fileList: FileList): void {
        let file = fileList[0];
        let fileReader: FileReader = new FileReader();
        let self = this;
        fileReader.onloadend = function (x) {
            self.file = fileReader.result;

        }
        fileReader.readAsText(file);

    }


}