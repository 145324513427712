import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DMSService } from "@app/dms.service";
import { SocketioService } from "@app/socketio.service";
declare var $: any;
// const ipc = require('electron').ipcRenderer
const selectDirBtn = document.getElementById('sourcePicker')


@Component({
    selector: 'app-dms',
    templateUrl: './dms.component.html',
    styleUrls: ['./dms.component.css']
})
export class DMSComponent {

    progress: number = 0;
    form: FormGroup;
    isTransferStarted: boolean = false;
    isalldaysselected: boolean = false;
    checkboxCounts: number = 0;
    isDeafultSet: boolean;
    totalmbtransffered: number = 0;
    isRunAtOption: boolean = true;
    refreshId;
    runTimeMain: object;
    checkeddays: any = [];
    consoleHistory: any = [];
    runStatus: any = '';
    totalSize: any = 0;
    totalFiles: any = 0;
    progressText: any = 'Connecting...';
    emailIDs;
    totalSizeTransferred: any = 0;
    totalFilesTransferred: any = 0;
    cancenRun: boolean;

    listofSchedules: any = [];
    listofHistory: any = [];

    showRunBar: boolean = false;
    runtype: string = '';
    pid;
    bandwidth = 0;
    resultFileData: string = '';
    resultFileIsReady: boolean = false;
    selectedHistroyRecord: any;
    currentUser;
    userShares;

    constructor(private router: Router, private fb: FormBuilder, private dmsService: SocketioService, private DMSService: DMSService, private ref: ChangeDetectorRef) {
        this.form = this.fb.group({
            compress: [true, ''],
            encrypt: [true, ''],
            incremental: [false, ''],
            verbose: [false, ''],
            debug: [false, ''],
            forceRemove: [false, ''],
            fetchOlder: [false, ''],
            emailIdsOption: [false, ''],
            emailIds: [''],
            session: ['Default'],
            threads: [''],
            streamSize: [''],
            parallelize: [''],
            fileListOption: [''],
            deleteLogsAfter: [''],
            reportFrequency: [''],
            fileList: [''],
            bandwidthLimit: [''],
            sourceIp: [''],
            sourceDirectory: [''],
            destinationIP: [''],
            destinationDirectory: [''],
            sourceDestiProject: this.fb.array([this.mountpoints])

        });
        this.currentUser = JSON.parse(localStorage.getItem('DMS_CURRENT_USER_DATA'));

    }
    ngOnInit() {
        this.mountsUpdate(1);

        this.DMSService.getSharesListForAdmin().subscribe((shares) => {
            //console.log("shares==>", shares);
            this.userShares = shares;
        });


        document.getElementById("sourcePicker").addEventListener("change", function (event) {
            let files = event.target;
            console.log(files);

        });
        document.getElementById("destinationPicker").addEventListener("change", function (event) {
            let files = event.target;
            console.log(files);

        });


        $('.profilebtn').on('click', function (event) {
            $('.profileBlock').toggle();

        });
        $(function () {
            $("#datepicker").datepicker({ dateFormat: 'mm-dd-yy' }).datepicker("setDate", new Date());
            // $("#datepicker").datepicker({ defaultDate: new Date() });
        });
        this.dmsService.socket.on('message', (data) => {
            this.consoleHistory.push(data);
        });

        this.dmsService.socket.on('runTotalFileAndSizeDetails', (res) => {
            console.log("runTotalFileAndSizeDetails:", res);
            this.progressText = 'Preparation Started...';
            let total = JSON.parse(res);
            this.totalSize = total.size;
            this.pid = total.pid;
            this.totalFiles = total.files.trim();
        });

        this.dmsService.socket.on('runStatus', (res) => {
            this.runStatus = res;
            console.log("runStatus:", res);
        });

        this.dmsService.socket.on('senderError', (res) => {
            this.showRunBar = false;
            alert(res);
            console.log("senderError:", res);
        });

        this.dmsService.socket.on('receivedResultFile', (res) => {
            this.resultFileData = res;
            this.resultFileIsReady = true;
            console.log("resultFileData:", res);
        });

        this.dmsService.socket.on('currentMBPS', (res) => {
            this.bandwidth = res;
            console.log("currentMBPS:", res);
        });

        this.dmsService.socket.on('runFileAndSizeDetails', (res) => {
            console.log("runFileAndSizeDetails:", res);
            if (this.runtype == 'now' && !this.cancenRun) {
                let totalTransfeered = JSON.parse(res);
                this.totalSizeTransferred = totalTransfeered.size;
                this.totalFilesTransferred = totalTransfeered.files.trim();
                this.progressText = 'Preparation Started...';
                let size = this.totalSizeTransferred;
                if (size > 0) {
                    this.progressText = 'Transferring Now...';
                    this.startProgressBar();
                }
            }
        });
    }
    ngAfterViewChecked() {

        if (!this.isDeafultSet) {
            switch (new Date().getDay()) {
                case 0:
                    $("#weekday-sun").prop('checked', true);
                    break;
                case 1:
                    $("#weekday-mon").prop('checked', true);
                    break;
                case 2:
                    $("#weekday-tue").prop('checked', true);
                    break;
                case 3:
                    $("#weekday-wed").prop('checked', true);
                    break;
                case 4:
                    $("#weekday-thu").prop('checked', true);
                    break;
                case 5:
                    $("#weekday-fri").prop('checked', true);
                    break;
                case 6:
                    $("#weekday-sat").prop('checked', true);
                    break;
            }
            this.isDeafultSet = true;
        }
    }
    getUserShares() {
        this.DMSService.getServersListForAdmin().subscribe();
    }
    submit(type) {
        this.runtype = type;


        if (type == "now") {
            this.showRunBar = true;
        }
        else if (type == "at") {

            this.runTimeMain = {};
            let runatOption = $('.scheudleoption:checked').val();
            if (runatOption == 'at') {
                let runatTimeInput = $('#runatTimeInput').val();
                let runatTimezoneInput = $('#runatTimezoneInput').val();
                let datepicker = $('#datepicker').val();
                this.runTimeMain = {
                    cron: false,
                    time: runatTimeInput,
                    timezone: runatTimezoneInput,
                    date: datepicker,
                    days: null
                }

            } else if (runatOption == 'cron') {
                let cronTimeInput = $('#cronTimeInput').val();
                this.checkeddays = [];
                let that = this;
                $("input:checkbox[class=weekday]:checked").each(function () {
                    console.log($(this).val());
                    that.checkeddays.push(parseInt($(this).val()));
                });
                this.runTimeMain = {
                    cron: true,
                    time: cronTimeInput,
                    timezone: null,
                    date: null,
                    days: this.checkeddays
                }
            }
            alert("Transfer Scheduled Successfully.");
        }

        let mainData = {
            formValues: this.form.value,
            when: type == 'at' ? this.runTimeMain : null
        }
        console.log(mainData)
        this.dmsService.sendMessage('newRunRequest', JSON.stringify(mainData));


    }
    listOfSchedules() {
        this.listofSchedules = JSON.parse(localStorage.getItem('User_Schedules'));
        console.log(this.listofSchedules);
    }
    showReadMore(blockID, btnID) {
        $(blockID).toggle();
        if ($(btnID).text() == "More Details") {
            $(btnID).text("Less Details");
        } else {
            $(btnID).text("More Details");
        };
    }
    listOfHistory() {
        this.listofHistory = JSON.parse(localStorage.getItem('User_History'));
        console.log(this.listofHistory);
    }
    getOptions(data) {
        let options = '';
        if (data.compress) {
            options = options + 'Compress,';
        }
        if (data.debug) {
            options = options + 'Debug,';
        }
        if (data.encrypt) {
            options = options + 'Encrypt,';
        }
        if (data.fetchOlder) {
            options = options + 'FetchOlder,';
        }
        if (data.forceRemove) {
            options = options + 'ForceRemove,';
        }
        if (data.incremental) {
            options = options + 'Incremental,';
        }
        if (data.verbose) {
            options = options + 'Verbose,';
        }

        return options.slice(0, -1);

    }
    getOtherOptions(data) {
        let options = '';

        if (data.bandwidthLimit) {
            options = options + 'BandwidthLimit=' + data.bandwidthLimit + ',';
        }
        if (data.reportFrequency) {
            options = options + 'ReportFrequency=' + data.reportFrequency + ',';
        }
        if (data.streamSize) {
            options = options + 'StreamSize=' + data.streamSize + ',';
        }
        if (data.threads) {
            options = options + 'Threads=' + data.threads + ',';
        }
        if (data.fileList) {
            options = options + 'FileList=' + data.fileList + ',';
        }
        return options.slice(0, -1);

    }

    logout() {
        this.dmsService.logout();
    }
    toggleAllDays() {
        this.isalldaysselected = !this.isalldaysselected;
    }
    startProgressBar() {



        //alert(runatOption);
        //return false;
        this.isTransferStarted = true;
        let that = this;
        // // let script = "diovdms --verbose --incremental --encrypt=[on|off] --compress=[on|off] --threads=NUMTHREADS --streamsize=STREAMSIZE --session=SESSIONNAME --runat=TIMESPEC --source=HOSTNAME:DIRECTORY --destination=HOSTNAME:DIRECTORY";
        // // alert(script);
        // let totalmb = 10796;


        // this.refreshId = setInterval(function () {
        //     if (!this.isTransferStarted) {
        //         clearInterval(this.refreshId);
        //     }


        //this.totalSize = 100
        // this.totalSizeTransferred = 20
        //that.progress = (that.totalSize * that.totalSizeTransferred) / 100;

        that.progress = (that.totalSizeTransferred / that.totalSize) * 100;
        // let bandarray = [10.3, 10.4, 10.5, 10.6, 10.7];
        // let sflarray = this.shuffle(bandarray);
        // this.bandwidth = sflarray[1];

        // console.log("totalSize==>", that.totalSize);
        // console.log("totalSizeTransferred==>", that.totalSizeTransferred);
        console.log("progress==>", that.progress);
        // that.totalmbtransffered = Math.round(that.totalmbtransffered + 107.96);
        if (that.progress >= 100) {
            this.progressText = 'Transfer Completed.';
            setTimeout(function () {
                //that.totalmbtransffered = totalmb;
                that.progress = 0;

                //clearInterval(this.refreshId);
                alert("Data transferred successfully!!");
                that.progressText = '';
                that.totalSizeTransferred = 0;
                that.totalFilesTransferred = 0;
                that.totalFiles = 0;
                that.totalSize = 0;
                that.isTransferStarted = false;
                that.showRunBar = false;
                //that.totalmbtransffered = 0;
                //that.progress = 0;
            }, 800);
        }
        //}, 100);
    }
    verifyIfallChecked() {
        setTimeout(function () {
            this.checkboxCounts = 0; //10796
            let that = this;
            $("input:checkbox[class=weekday]:checked").each(function () {
                that.checkboxCounts = that.checkboxCounts + 1;
            });
            if (that.checkboxCounts == 7) {
                $("#allselcbtn").prop('checked', true);
                //this.isalldaysselected = true;
            } else {
                $("#allselcbtn").prop('checked', false);
            }
            // console.log(that.checkboxCounts);
        }, 500);
    }

    socketTEst() {
        this.dmsService.sendMessage('createMessage', 'hello');
    }

    runAtOptionChange() {
        this.isRunAtOption = !this.isRunAtOption;
        if (this.isRunAtOption) {
            $(function () {
                $("#datepicker").datepicker({ dateFormat: 'mm-dd-yy' }).datepicker("setDate", new Date());
                // $("#datepicker").datepicker({ defaultDate: new Date() });
            });
        }
    }
    cancelRun() {
        this.cancenRun = true;
        this.dmsService.sendMessage('cancelRun', this.pid);
        this.isTransferStarted = false;
        this.showRunBar = false;
        this.totalmbtransffered = 0;
        this.progress = 0;
    }
    clearConsoleLogs() {
        this.consoleHistory = [];
    }
    sendEmail() {
        let newlistofEmail = $('#newlistofEmail').val();
        let data = {
            filename: this.selectedHistroyRecord.ResultsFile,
            emailIds: newlistofEmail,
            runID: this.selectedHistroyRecord.RunID,
            runStartTime: this.selectedHistroyRecord.StartTime
        }
        this.dmsService.sendMessage('sendEmail', JSON.stringify(data));
        alert("Email sent successfully.");
    }
    shuffle(arra1) {
        var ctr = arra1.length, temp, index;

        // While there are elements in the array
        while (ctr > 0) {
            // Pick a random index
            index = Math.floor(Math.random() * ctr);
            // Decrease ctr by 1
            ctr--;
            // And swap the last element with it
            temp = arra1[ctr];
            arra1[ctr] = arra1[index];
            arra1[index] = temp;
        }
        return arra1;
    }
    deleteSchedule(id) {
        this.dmsService.sendMessage('deleteSchedule', id);
    }
    getResultFile(filename) {
        this.dmsService.sendMessage('getResultFile', filename);
    }
    getTimeforSchedule(sch) {
        if (sch.RunDay) {
            console.log("coming here==>", sch.RunDay);
            var day = '';
            switch (sch.RunDay) {
                case 0:
                    day = 'Sunday';
                    break;
                case 1:
                    day = 'Monday';
                    break;
                case 2:
                    day = 'Tuesday';
                    break;
                case 3:
                    day = 'Wednesday';
                    break;
                case 4:
                    day = 'Thursday';
                    break;
                case 5:
                    day = 'Friday';
                    break;
                case 6:
                    day = 'Saturday';
                    break;

            }
            console.log("day==>", day);
            return day + ' ' + sch.RunTime;
        } else {
            return sch.RunTime;
        }
    }
    chooseFolder() {
        $.ajax({
            type: "GET",
            url: "http://khushwantyadav.com/file.py",
            data: {},
            success: (data) => {
                console.log(data);
            }
        });
    }
    get mountpoints(): FormGroup {
        return this.fb.group({
            sourceShareID: [''],
            desitnationShareID: [''],
            projectID: ['']
        });
    }
    mountsUpdate(value, data?) {
        const control = <FormArray>this.form.get('sourceDestiProject');
        while (control.length !== 0) {
            control.removeAt(0);
        }
        for (let i = 0; i < value; i++) {
            if (data && data.length == value) {
                control.push(this.fb.group({
                    value: [data[i]]
                }));
                control.disable();
            } else {
                control.push(this.mountpoints)
            }
        }

    }
    addMount(value) {
        const control = <FormArray>this.form.get('sourceDestiProject');
        for (let i = 1; i <= value; i++) {
            control.push(this.mountpoints)
        }


        this.ref.detectChanges();
    }
    removeMount() {
        const control = <FormArray>this.form.get('sourceDestiProject');
        if (control.length != 0) {
            control.removeAt(0);
        }
    }
}