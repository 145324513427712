import { Component, Input } from "@angular/core";
import { barchart } from '../js/bargraph'



@Component({
    selector: 'bar-graph',
    templateUrl: './bar-graph.component.html',
    styleUrls: ['./bar-graph.component.css']
})
export class BarGraphComponent {
    @Input() data: any;

    graphData: any;

    constructor() {

    }

    ngOnInit() {
        this.graphData = this.data;
        //js.bargraph();

    }
    ngAfterViewInit() {
        barchart(this.graphData.graphtype, this.graphData.options, this.graphData.element);
    }

}