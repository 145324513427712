import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DMSService } from "@app/dms.service";

declare var $: any;


@Component({
    selector: 'dms-admin-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.css']
})
export class DMSAdminProjectsComponent {
    sharesList: any = [];
    clientList: any = [];
    projectsList: any = [];
    form: FormGroup;
    totalNoOfMounts;
    isFirstTimeLoad: boolean = true;
    isShowForm: boolean = false;
    currentUserData;


    constructor(private dmsService: DMSService, private fb: FormBuilder, private ref: ChangeDetectorRef) {
        this.form = this.fb.group({
            Name: [''],
            MirrorsPoints: this.fb.array([this.mountpoints])
        }
        );
        this.sharesList = JSON.parse(localStorage.getItem('DMS_SHARES_LIST'));
        this.projectsList = JSON.parse(localStorage.getItem('DMS_PROJECTS_LIST'));
        this.dmsService.listOfProjects.subscribe((projects) => {
            this.projectsList = projects;
        });
        this.dmsService.listOfShares.subscribe((shares) => {
            this.sharesList = shares;
            if (this.isFirstTimeLoad) {
                this.mountsUpdate(1);
                this.isShowForm = true;
                this.isFirstTimeLoad = false;
            }

        });
        this.currentUserData = JSON.parse(localStorage.getItem('DMS_CURRENT_USER_DATA'));
    }
    ngOnInit() {


    }
    get mountpoints(): FormGroup {
        return this.fb.group({
            ShareID: ['']
        });
    }
    mountsUpdate(value, data?) {
        const control = <FormArray>this.form.get('MirrorsPoints');
        while (control.length !== 0) {
            control.removeAt(0);
        }
        for (let i = 0; i < value; i++) {
            if (data && data.length == value) {
                control.push(this.fb.group({
                    value: [data[i]]
                }));
                control.disable();
            } else {
                control.push(this.mountpoints)
            }
        }

    }
    addMount(value) {
        const control = <FormArray>this.form.get('MirrorsPoints');
        for (let i = 1; i <= value; i++) {
            control.push(this.mountpoints)
        }
        this.totalNoOfMounts = this.totalNoOfMounts + 1;

        this.ref.detectChanges();
    }
    removeMount() {
        const control = <FormArray>this.form.get('MirrorsPoints');
        if (control.length != 0) {
            control.removeAt(0);
            this.totalNoOfMounts = this.totalNoOfMounts - 1;
        }
    }
    addMountinDB() {
        let formVlues = this.form.value;

        // let body = {
        //     MountPoints: formVlues
        // }
        // console.log(formVlues);
        formVlues['createdBy'] = this.currentUserData['UserName'];
        this.dmsService.addProjectssByAdmin(formVlues).subscribe(res => {
            this.dmsService.getProjectsListForAdmin().subscribe();
            $('#addprojectCancelBtn').click();
            alert("Project is created successfully.");
        });
    }


}