import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./core/helpers/jwt.interceptor";
import { DMSComponent } from "./dms/dms.component";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { SocketioService } from "./socketio.service";
import { DMSAdminComponent } from "./dms-admin/dms-admin.component";
import { GraphsModule } from "./graphs/graphs.module";
import { DMSService } from "./dms.service";
import { AuthenticationService } from "./core/services";
import { DMSAdminUsersComponent } from "./dms-admin/components/users/users.component";
import { DMSAdminServersComponent } from "./dms-admin/components/servers/servers.component";
import { DMSAdminSharesComponent } from "./dms-admin/components/shares/shares.component";
import { DMSAdminMountsComponent } from "./dms-admin/components/mounts/mounts.component";
import { DMSAdminClientsComponent } from "./dms-admin/components/clients/clients.component";
import { StoreModule } from "@ngrx/store";
import { getOrganizationReducer } from "./reducers/organization.reducer";
import { OrganizationDataResolve } from "./resolver/organization.resolve";
import { AdminDataResolve } from "./resolver/admin.resolve";
import { DMSAdminProjectsComponent } from "./dms-admin/components/projects/projects.component";
import { SalesforceComponent } from "./salesforce/salesforce.component";
import { SalesforceExtensionComponent } from "./salesforce-extension/salesforce-extension.component";
//import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

//const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DMSComponent,
    DMSAdminComponent,
    DMSAdminUsersComponent,
    DMSAdminServersComponent,
    DMSAdminSharesComponent,
    DMSAdminMountsComponent,
    DMSAdminClientsComponent,
    DMSAdminProjectsComponent,
    SalesforceComponent,
    SalesforceExtensionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    AmazingTimePickerModule,
    GraphsModule,
    FormsModule,
    StoreModule.forRoot({ organization: getOrganizationReducer }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    SocketioService,
    DMSService,
    AuthenticationService,
    OrganizationDataResolve,
    AdminDataResolve,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
