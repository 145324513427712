import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { DMSService } from "@app/dms.service";
import { Store } from "@ngrx/store";
import { AppState } from "@app/app.state";



@Injectable()
export class AdminDataResolve implements Resolve<any> {
    subdomain: string = '';
    orgID;
    constructor(private dmsService: DMSService, private store: Store<AppState>,) {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        this.store.select(state => state).subscribe(data => {
            console.log('datafromstore', data);
        });
        // this.orgID = this.store.select('organization').subscribe();
        console.log("all call stated");

        //store listOfUsers
        this.dmsService.bulkCall();
        //this.dmsService.getUsersListForAdmin();
        //this.dmsService.getServersListForAdmin();
        let clientsList = this.dmsService.getClientsListForAdmin();
        // this.dmsService.getSharesListForAdmin();
        //this.dmsService.getMountsListForAdmin();
        console.log("all call done");
        //localStorage.setItem('DMS_USER_LIST', JSON.stringify(usersList));
        //localStorage.setItem('DMS_SERVER_LIST', JSON.stringify(serversList));
        //localStorage.setItem('DMS_CLIENTS_LIST', JSON.stringify(clientsList));


        return clientsList;

    }
}