import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@core/models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public accessTokenSubject: BehaviorSubject<any>;
    public currentUser: Observable<User>;

    //baseUrl: string = 'http://localhost:8080/';  //local
    //baseUrl: string = 'http://ec2-18-224-213-220.us-east-2.compute.amazonaws.com:8080';  //marketing

    constructor(private http: HttpClient) {
        localStorage.setItem('currentUser', JSON.stringify({ name: 'sds' }));
        this.currentUserSubject = localStorage.getItem('currentUser') && new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser'))) || null;
        this.currentUser = this.currentUserSubject.asObservable();
        this.accessTokenSubject = new BehaviorSubject<any>(null);
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    public get accessToken(): User {
        return this.accessTokenSubject.value;
    }



    login(body) {
        return this.http.post<any>(`${environment.apiUrl}/login/v1`, body, {
            headers: {
                "Content-Type": "application/json"
            }
        }).pipe(map(user => {
            console.log("res==>", user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user['data']));
            localStorage.setItem('accessToken', user['token']['access_token']);
            this.currentUserSubject.next(user);
            this.accessTokenSubject.next(user['token']['access_token']);
            return user;
        }));
        // return new Promise((resolve, reject) => {
        //     if (username == "dynanimda" && password == "h!^&*^@*70%5jkhu7x76%") {
        //         let user = {
        //             id: 1,
        //             username: username,
        //             password: "dummy",
        //             firstName: "dummy",
        //             lastName: "dummy",
        //             token: "dummy"
        //         }
        //         // store user details and jwt token in local storage to keep user logged in between page refreshes
        //         localStorage.setItem('currentUser', JSON.stringify(user));
        //         this.currentUserSubject.next(user);
        //         resolve(user);
        //     } else {
        //         reject('Invalid Details');
        //     }

        // })

    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.accessTokenSubject.next(null);
    }
    refreshToken() {
        return this.http.get("/refreshtoken");
    }
}