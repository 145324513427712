import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { delay, map, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { io } from "socket.io-client";
import { environment } from "../environments/environment";
import { Organization } from "./organization/organization.model";
import { BehaviorSubject, combineLatest } from "rxjs";

@Injectable({ providedIn: "root" })
export class DMSService {
  public socket;
  totalUsersForAdmin: number = 0;
  totalServersForAdmin: number = 0;
  totalSharesForAdmin: number = 0;
  totalMountsForAdmin: number = 0;

  listOfClients: BehaviorSubject<object> = new BehaviorSubject({});
  listOfProjects: BehaviorSubject<object> = new BehaviorSubject({});
  listOfServers: BehaviorSubject<object> = new BehaviorSubject({});
  listOfShares: BehaviorSubject<object> = new BehaviorSubject({});

  //baseURL = "https://ec2-44-197-200-208.compute-1.amazonaws.com:8080/";
  //https://server1.dynavisor.net/
  //http://localhost:8080/
  baseURL = "https://server1.dynavisor.net/";

  totalNumberOfUsersForAdmin: any;

  constructor(private http: HttpClient, private router: Router) {}

  bulkCall() {
    const requests = [
      this.http.get<any>(this.baseURL + "users/get"),
      this.http.get<any>(this.baseURL + "servers/get"),
      this.http.get<any>(this.baseURL + "share/get"),
      this.http.get<any>(this.baseURL + "mount/get"),
      this.http.get<any>(this.baseURL + "clients/get"),
      this.http.get<any>(this.baseURL + "projects/get"),
    ];
    combineLatest(
      requests.map((req) => req.pipe(tap((res) => handleResponse(res))))
    ).subscribe();
    const handleResponse = (response) => {
      switch (response.type) {
        case "Users":
          localStorage.setItem("DMS_USER_LIST", JSON.stringify(response.data));
          break;
        case "Servers":
          localStorage.setItem(
            "DMS_SERVER_LIST",
            JSON.stringify(response.data)
          );
          this.listOfServers.next(response.data);
          break;
        case "Shares":
          localStorage.setItem(
            "DMS_SHARES_LIST",
            JSON.stringify(response.data)
          );
          this.listOfShares.next(response.data);
          break;
        case "Mounts":
          localStorage.setItem(
            "DMS_MOUNTS_LIST",
            JSON.stringify(response.data)
          );
          break;
        case "Clients":
          localStorage.setItem(
            "DMS_CLIENTS_LIST",
            JSON.stringify(response.data)
          );
          this.listOfClients.next(response.data);
          break;
        case "Projects":
          localStorage.setItem(
            "DMS_PROJECTS_LIST",
            JSON.stringify(response.data)
          );
          this.listOfProjects.next(response.data);
          break;
      }
      //console.log("all API call response", response);
    };
  }
  getHistroyRecordsForAdmin() {
    return this.http.get(this.baseURL + "history/get");
  }
  getUsersListForAdmin() {
    return this.http.get<any>(this.baseURL + "users/get").pipe(
      map((users) => {
        localStorage.setItem("DMS_USER_LIST", JSON.stringify(users.data));
        return users.data;
      })
    );
  }
  getServersListForAdmin() {
    return this.http.get<any>(this.baseURL + "servers/get").pipe(
      map((servers) => {
        localStorage.setItem("DMS_SERVER_LIST", JSON.stringify(servers.data));
        this.listOfServers.next(servers.data);
        return servers.data;
      })
    );
  }
  getSharesListForAdmin() {
    return this.http.get<any>(this.baseURL + "share/get").pipe(
      map((shares) => {
        localStorage.setItem("DMS_SHARES_LIST", JSON.stringify(shares.data));
        this.listOfShares.next(shares.data);
        return shares.data;
      })
    );
  }
  getMountsListForAdmin() {
    return this.http.get<any>(this.baseURL + "mount/get").pipe(
      map((mounts) => {
        localStorage.setItem("DMS_MOUNTS_LIST", JSON.stringify(mounts.data));
        return mounts.data;
      })
    );
  }
  getClientsListForAdmin() {
    return this.http.get<any>(this.baseURL + "clients/get").pipe(
      map((clients) => {
        localStorage.setItem("DMS_CLIENTS_LIST", JSON.stringify(clients.data));
        this.listOfClients.next(clients.data);
        return clients.data;
      })
    );
  }
  getProjectsListForAdmin() {
    return this.http.get<any>(this.baseURL + "projects/get").pipe(
      map((projects) => {
        localStorage.setItem(
          "DMS_PROJECTS_LIST",
          JSON.stringify(projects.data)
        );
        this.listOfProjects.next(projects.data);
        console.log("projects updated");
        return projects.data;
      })
    );
  }
  getOrganizationLogo(domain) {
    return this.http
      .get<Organization[]>(this.baseURL + "organization/get/" + domain)
      .pipe(delay(1000));
  }
  login(data) {
    return this.http.post(this.baseURL + "login/v1", data);
  }
  addUserByAdmin(data) {
    return this.http.post(this.baseURL + "register/user", data);
  }
  addServerByAdmin(data) {
    return this.http.post(this.baseURL + "register/server", data);
  }
  addShareByAdmin(data) {
    return this.http.post(this.baseURL + "share/create", data);
  }
  addMountByAdmin(data) {
    return this.http.post(this.baseURL + "mount/create", data);
  }
  addClientsByAdmin(data) {
    return this.http.post(this.baseURL + "clients/create", data);
  }
  addProjectssByAdmin(data) {
    return this.http.post(this.baseURL + "projects/create", data);
  }

  //testing
  archiveNow(data) {
    return this.http.post<any>(this.baseURL + "sf/get", data);
  }
  retriveNow(name) {
    return this.http.post<any>(this.baseURL + "sf/retr", name);
  }
  advancedSearch(searchText) {
    return this.http.post<any>(this.baseURL + "sf/advancedSearch", searchText);
  }
  archiveNowLatest(data) {
    return this.http.post<any>(this.baseURL + "sf/archivenow", data);
  }
  getAllObjects() {
    return this.http.get<any>(this.baseURL + "sf/getallObjects");
  }
  getAllFields(data) {
    return this.http.post<any>(this.baseURL + "sf/getAllFields", data);
  }
  archivenowFetch(data) {
    return this.http.post<any>(this.baseURL + "sf/archivenowFetch", data);
  }
}
