import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { DMSService } from "@app/dms.service";
import { Organization } from "@app/organization/organization.model";

@Injectable()
export class OrganizationDataResolve implements Resolve<Organization[]> {
    subdomain: string = '';
    constructor(private dmsService: DMSService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Organization[]> {

        const domain = window.location.hostname;
        if (domain.indexOf('.') > 0) {
            this.subdomain = domain.split('.')[0];
        } else {
            this.subdomain = 'caresoft'
        }
        console.log('subdomain', this.subdomain);

        return this.dmsService.getOrganizationLogo(this.subdomain);

    }
}