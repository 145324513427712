import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { io } from 'socket.io-client';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class SocketioService {

    public socket;
    fileBaseURL = 'file:///Users/khushwantyadav/Documents/Projects/node-ssh/';

    constructor(private http: HttpClient, private router: Router) { }

    setupSocketConnection(username, password) {
        //http://ec2-100-24-118-109.compute-1.amazonaws.com:3000
        //http://65.111.255.108:3000
        this.socket = io('http://65.111.255.113:3000');
        this.socket.on('connect', () => {
            this.socket
                .emit('authenticate', { auth: { username: username, password: password } }) //send the jwt
                .on('authenticated', (data) => {
                    localStorage.setItem('DMSUSERID', data);
                    console.log('authenticated', data);
                    this.route();
                })
                .on('unauthorized', (msg) => {
                    console.log(`unauthorized: ${JSON.stringify(msg)}`);

                })
                .on('listOfSchedules', (data) => {
                    localStorage.setItem('User_Schedules', JSON.stringify(data));
                    //console.log(`listOfSchedules: ${JSON.stringify(data)}`);

                })
                .on('listofHistory', (data) => {
                    localStorage.setItem('User_History', JSON.stringify(data));
                    //console.log(`listofHistory: ${JSON.stringify(data)}`);

                })
        });
    }
    route() {
        this.router.navigate(['/dms']);
    }
    sendMessage(type, msg: string) {
        this.socket.emit(type, msg);
    }
    getMessage(event) {
        return this.socket.fromEvent(event).pipe(map((data: any) => data));
    }
    logout() {
        this.socket.disconnect();
        localStorage.removeItem('DMSUSERID');
        this.router.navigate(['/login']);
    }

}