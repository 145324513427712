import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './app.state';
import { DMSService } from './dms.service';
import { Organization } from './organization/organization.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dynavisor-web';
  constructor(
    private dMSService: DMSService,
    private store: Store<AppState>
  ) {
    // this.getClientLogo('caresoft');
  }
  // async getClientLogo(domain) {
  //   await this.dMSService.getOrganizationLogo(domain).subscribe(res => {
  //     console.log("Organization data stored in store");
  //     this.store.dispatch({
  //       type: 'ORGANIZATION_DETAILS',
  //       payload: <Organization>res['data'][0]
  //     });
  //   });
  // }
}
