import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { DMSService } from "@app/dms.service";

declare var $: any;


@Component({
    selector: 'dms-admin',
    templateUrl: './dms-admin.component.html',
    styleUrls: ['./dms-admin.component.css']
})
export class DMSAdminComponent {
    historyList: any = [];
    usersList: any = [];
    serversList: any = [];
    clientsList: any = [];
    sharesList: any = [];
    mountsList: any = [];
    projectsList: any = [];

    highareaGraphData_CPU = {
        options: {
            chart: {
                height: 150,
                type: 'column'
            },
            title: {
                style: {
                    color: '#1f4192',
                    fontWeight: 'bold',
                    fontSize: '14px'
                },
                text: 'CPU Utilization (%)'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: ['100', '90', '80', '70', '60', '50', '40', '30', '20', '10'],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}% </span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                showInLegend: false,
                name: 'CPU',
                data: [2000, 4989, 3434, 4453, 5645, 3423, 5454, 2312, 5645, 3231]
            }]
        },
        element: "high-area-chart-stats_cpu",
        graphtype: "highareachart"
    };
    highareaGraphData_Network = {
        options: {
            chart: {
                height: 150,
                type: 'column'
            },
            title: {
                style: {
                    color: '#1f4192',
                    fontWeight: 'bold',
                    fontSize: '14px'
                },
                text: 'Network Utilization (%)'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: ['100', '90', '80', '70', '60', '50', '40', '30', '20', '10'],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}% </span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                showInLegend: false,
                name: 'Network',
                data: [5000, 4343, 2456, 3456, 6789, 4356, 4635, 6745, 3465, 4536]
            }]
        },
        element: "high-area-chart-stats_network",
        graphtype: "highareachart"
    };
    highareaGraphData_Memory = {
        options: {
            chart: {
                height: 150,
                type: 'column'
            },
            title: {
                style: {
                    color: '#1f4192',
                    fontWeight: 'bold',
                    fontSize: '14px'
                },
                text: 'Memory Utilization (%)'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: ['100', '90', '80', '70', '60', '50', '40', '30', '20', '10'],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}% </span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                showInLegend: false,
                name: 'Memory',
                data: [4344, 4544, 3434, 6766, 3333, 5788, 2323, 4545, 7878, 4000]
            }]
        },
        element: "high-area-chart-stats_memory",
        graphtype: "highareachart"
    };
    constructor(
        private dmsService: DMSService,
        private route: ActivatedRoute
    ) {
        this.usersList = JSON.parse(localStorage.getItem('DMS_USER_LIST'));
        this.serversList = JSON.parse(localStorage.getItem('DMS_SERVER_LIST'));
        this.clientsList = JSON.parse(localStorage.getItem('DMS_CLIENTS_LIST'));
        this.sharesList = JSON.parse(localStorage.getItem('DMS_SHARES_LIST'));
        this.mountsList = JSON.parse(localStorage.getItem('DMS_MOUNTS_LIST'));
        this.projectsList = JSON.parse(localStorage.getItem('DMS_PROJECTS_LIST'));

        console.log("this.sharesList==>", this.sharesList.length);
        console.log("this.mountsList==>", this.mountsList.length);
        this.getHistoryData();
    }
    ngOnInit() {

    }
    getHistoryData() {
        this.dmsService.getHistroyRecordsForAdmin().subscribe(res => {
            console.log("hostroty==>", res);
            this.historyList = res['data'];
        });
    }
}