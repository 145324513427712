import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { DMSService } from "@app/dms.service";


declare var $: any;


@Component({
    selector: 'app-salesforce',
    templateUrl: './salesforce.component.html',
    styleUrls: ['./salesforce.component.css']
})
export class SalesforceComponent {

    showMiniObjctBox: boolean;
    showMainObjectBox: boolean;
    showArchveBox: boolean;
    showCompleteArchval: boolean;
    showCompleteRetrival: boolean;
    userRetrived: any;
    userdataretrived: boolean;
    showdashboard: boolean = true;

    constructor(
        private dmsService: DMSService
    ) { 
        this.showCompleteArchival();
    }

    ngOnInit() {
        $(function () {
            $("#datepicker").datepicker({ dateFormat: 'mm-dd-yy' }).datepicker("setDate", new Date());
            // $("#datepicker").datepicker({ defaultDate: new Date() });
        });
    }
    toggleSubObjectBox() {
        this.showMiniObjctBox = !this.showMiniObjctBox;
    }
    toggleMainObjectBox() {
        this.showMainObjectBox = !this.showMainObjectBox;
    }
    toggleArchiveBox() {
        this.showdashboard = false;
        this.showArchveBox = !this.showArchveBox;
        $(function () {
            $("#datepicker").datepicker({ dateFormat: 'mm-dd-yy' }).datepicker("setDate", new Date());
            // $("#datepicker").datepicker({ defaultDate: new Date() });
        });

    }
    archivenow() {
        const agentname = $('#agentname').val();
        let data = {
            agentName: agentname
        }
        this.dmsService.archiveNow(data).subscribe(res => {
            console.log(res);
            alert("Data Archived successfully");
        });
    }
    showCompleteArchival() {
        this.showdashboard = false;
        this.showCompleteArchval = true;
        this.showCompleteRetrival = false;
    }
    showCompleteRetrivall() {
        this.showdashboard = false;
        this.showCompleteArchval = false;
        this.showCompleteRetrival = true;
        this.showMainObjectBox = false;
        this.showMiniObjctBox = false;
        this.showArchveBox = false;
    }
    retreivenow() {
        this.userdataretrived = false;
        const retrievename = $('#retrievename').val();
        console.log(retrievename);
        let data = {
            name: retrievename
        }

        this.dmsService.retriveNow(data).subscribe(res => {
            console.log(res);
            this.userdataretrived = true;
            this.userRetrived = res.data.records[0];
            alert("Data Retrieved successfully");

        });


    }
}